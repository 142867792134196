import React, { useEffect, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { IoRefresh } from "react-icons/io5";
import { ErrorToast } from "../customToast";
import "./captchaStyle.css";

const Captcha = ({
  generatedCaptcha,
  setGeneratedCaptcha,
  register,
  errors,
}) => {
  const captchaInputRef = useRef();
  const canvasRef = useRef();
  const alphabets = "AaBbCcDdEeFfGgHhJjKkMmNnOoPpQqRrSsTtUuVvWwXxYyZz";

  const generate = () => {
    let first = alphabets[Math.floor(Math.random() * alphabets.length)];
    let second = Math.floor(Math.random() * 10);
    let third = Math.floor(Math.random() * 10);
    let fourth = alphabets[Math.floor(Math.random() * alphabets.length)];
    let fifth = alphabets[Math.floor(Math.random() * alphabets.length)];
    let sixth = Math.floor(Math.random() * 10);

    const captchaText = `${first}${second}${third}${fourth}${fifth}${sixth}`;
    localStorage.setItem("captcha", captchaText);
    setGeneratedCaptcha(captchaText);
  };

  const drawCaptcha = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Background
      ctx.fillStyle = "#f4f4f4";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Different colors for each character
      const colors = [
        "#C70039",
        "#008F39",
        "#002FA7",
        "#A70063",
        "#CC9900",
        "#4B0082",
      ];

      ctx.font = "28px Times New Roman";

      let x = 10; // Starting x position
      for (let i = 0; i < generatedCaptcha.length; i++) {
        ctx.fillStyle = colors[i % colors.length]; // Cycle through colors
        ctx.fillText(generatedCaptcha[i], x, 30);
        x += 25; // Space between characters
      }
    }
  };

  useEffect(() => {
    generate();
  }, []);

  useEffect(() => {
    if (generatedCaptcha) {
      drawCaptcha();
    }
  }, [generatedCaptcha]);

  return (
    <div>
      <Row>
        <Col xs={9} className="pe-0">
          <canvas
            ref={canvasRef}
            width={160}
            height={40}
            style={{ borderRadius: "6px" }}
          />
        </Col>

        <Col xs={3}>
          <div
            onClick={generate}
            className="d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: "rgb(11 146 75)",
              borderRadius: "6px",
              height: "40px",
              cursor: "pointer",
            }}
          >
            <IoRefresh size={25} color="white" />
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col style={{ position: "relative", width: "400px" }}>
          <Form.Control
            type="text"
            maxLength={6}
            onKeyPress={(e) => {
              if (e.target.value.length >= 6) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              ErrorToast("Can not paste on field!");
            }}
            onCopy={(e) => {
              e.preventDefault();
              ErrorToast("Can not copy from field!");
            }}
            style={{
              width: "100%",
              height: "40px",
              padding: "0 10px",
              borderRadius: 0,
              borderLeft: "hidden",
              borderRight: "hidden",
              borderTop: "hidden",
            }}
            className="mt-2 login-form-field"
            placeholder="Enter the captcha..."
            ref={captchaInputRef}
            {...register("captcha", { required: "Captcha required!" })}
          />
          {errors.captcha && (
            <small className="text-danger">{errors.captcha.message}</small>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Captcha;
