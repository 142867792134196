// war file local (war file)
// export const baseURL = process.env.REACT_APP_BASE_API;

//global
// export const baseURL = process.env.REACT_APP_GLOBAL_BASE_API;

// AWS
export const baseURL = process.env.REACT_APP_AWS_API;

// map
export const mapUrl = process.env.REACT_APP_MAP_API;

// Tank Image
export const tankImageUrl = "https://odisha4kgeo.in/index.php";

//  Satya LAN
//  export const baseURL = 'http://172.1.14.71:8080/api/v1';

//  Satya WiFi
//  export const baseURL = 'http://172.31.99.31:8080/api/v1'

//  Bhagyashreee LAN
//  export const baseURL = "http://192.168.1.106:8080/api/v1";

//  Bhagyashreee WiFi
// export const baseURL = "http://172.31.99.140:8080/api/v1";

//  Maruti WiFi
//  export const baseURL = "http://172.31.99.201:8080/api/v1";

//  Maruti LAN
//  export const baseURL = "http://192.168.1.65:8080/api/v1";
